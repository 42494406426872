import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  async deleteShippingLabel(labelId) {
    const result = await axios.delete(endpoints.shipmentOrderLabel.Controller.deleteShippingLabel(labelId))
    return result.data.body
  },

  async getShippingLabels(data) {
    const result = await axios.post(endpoints.shipmentOrderLabel.Controller.getShippingLabels(), data)
    return result
  },

  async getFbmForceShippingLabelList(fbmOrderId) {
    const result = await axios.get(endpoints.shipmentOrderLabel.Controller.getFbmForceShippingLabelList(fbmOrderId))
    return result.data.body
  },

  async getRemainingBoxItems(fbmOrderId) {
    const result = await axios.get(endpoints.shipmentOrderLabel.Controller.getRemainingBoxItems(fbmOrderId))
    return result.data.body
  },

  async createShippingLabel(data) {
    const result = await axios.post(endpoints.shipmentOrderLabel.Controller.createShippingLabel(), data)
    return result
  },

  async deleteShippingLabelBox(shippingLabelId) {
    const result = await axios.delete(endpoints.shipmentOrderLabel.Controller.deleteShippingLabelBox(shippingLabelId))
    return result.data.body
  },

  async createShippingLabelBox(shippingLabelId, data) {
    const result = await axios.post(endpoints.shipmentOrderLabel.Controller.createShippingLabelBox(shippingLabelId), data)
    return result
  },

  async updateShippingLabel(data) {
    const result = await axios.put(endpoints.shipmentOrderLabel.Controller.updateShippingLabel(), data)
    return result
  },

  async getCarriers() {
    const result = await axios.get(endpoints.shipmentOrderLabel.Controller.getCarriers())
    return result.data.body
  },

  async uploadShippingLabel(data) {
    const result = await axios.post(endpoints.shipmentOrderLabel.Controller.uploadShippingLabel(), data)
    return result
  },

}
