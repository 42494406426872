<template>
  <div>
    <div class="card m-0">
      <div class="card-header pr-half pl-1 py-half bg-light-primary rounded m-0">
        <div class="card-title">
          Upload File / URL Information
        </div>
      </div>
      <dx-util-form
        ref="customerProvidedLabelFormRef"
        :show-colon-after-label="true"
        :form-data="shippingLabelUrlForm"
        label-mode="floating"
        label-location="left"
      >
        <dx-util-group-item :col-count="2">
          <dx-util-simple-item data-field="carrier" editor-type="dxSelectBox" :editor-options="carrierOptions">
            <dx-util-required-rule message="Carrier is required" />
          </dx-util-simple-item>
          <dx-util-simple-item data-field="trackingNumber">
            <dx-util-required-rule message="Tracking Number is required" />
          </dx-util-simple-item>
        </dx-util-group-item>
        <dx-util-group-item :col-count="12">
          <dx-util-simple-item data-field="labelUrl" :col-span="9">
            <dx-util-required-rule message="Label URL is required" />
          </dx-util-simple-item>
          <dx-util-item template="uploadButtonTemplate" :col-span="3" />
        </dx-util-group-item>
        <template #uploadButtonTemplate>
          <div class="pt-1" style="min-width:90px">
            <input ref="fileInput" type="file" style="display:none" @change="handleFileUpload">
            <dx-util-button text="Select File" type="default" class="w-100" @click="uploadFile" />
          </div>
        </template>
      </dx-util-form>
      <div class="d-flex pt-1">
        <div class="w-50 pr-half">
          <dx-util-button class="d-block d-inline" type="danger" text="Cancel" @click="$emit('emit-close')" />
        </div>
        <div class="w-50 pl-half">
          <dx-util-button class="d-block d-inline" type="success" text="Save" @click="saveUrl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import labelService from '@/http/requests/external/label'
import fbmService from '@/http/requests/fbm/fbmService'
import useCurrentUser from '@/libs/app/current-user'
import useFbmState from '../useFbmStates'

export default {
  data() {
    return {
      shippingLabelUrlForm: {
        companyId: null,
        carrier: '',
        trackingNumber: '',
        labelUrl: '',
        fbmOrderId: null,
        shippingLabelSourceType: 'BOL',
      },
    }
  },
  setup() {
    const {
      carriers, currentOrder, getShippingLabels, getCarrierList,
    } = useFbmState()
    const { userCompanyType } = useCurrentUser()
    return {
      carriers,
      currentOrder,
      userCompanyType,
      getShippingLabels,
      getCarrierList,
    }
  },
  computed: {
    carrierOptions() {
      return {
        items: this.carriers,
        value: this.shippingLabelUrlForm.carrier,
        autoComplete: 'none',
        searchEnabled: true,
        displayExpr: 'text',
        valueExpr: 'value',
        onValueChanged: this.setSelectedCarrier,
        onCustomItemCreating: this.onCustomItemCreating,
        UrlPattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
        acceptCustomValue: true,
        dropDownOptions: {
          height: 300,
        },
        inputAttrs: {
          autocomplete: 'off',
        },
      }
    },
  },
  watch: {
    'currentOrder.id': {
      immediate: true,
      deep: true,
      handler() {
        const formRef = this.$refs.customerProvidedLabelFormRef.instance
        formRef.resetValues()
      },
    },
  },
  mounted() {
    this.getCarrierList()
  },
  methods: {
    setSelectedCarrier(e) {
      this.shippingLabelUrlForm.carrier = e.value
    },
    onCustomItemCreating(e) {
      const { text } = e
      const item = {
        text,
        value: text,
      }
      e.customItem = item
    },
    uploadFile() {
      this.$refs.fileInput.click()
    },
    async handleFileUpload(event) {
      this.file = event.target.files[0]
      const formData = new FormData()
      formData.append('file', this.file)
      const locationResponse = await labelService.uploadShippingLabel(formData)
      this.shippingLabelUrlForm.labelUrl = locationResponse.data
    },
    async saveUrl() {
      const formRef = this.$refs.customerProvidedLabelFormRef.instance
      if (this.userCompanyType === 'CUSTOMER') {
        this.shippingLabelUrlForm.shippingLabelSourceType = 'CUSTOMER'
      }
      this.shippingLabelUrlForm.companyId = this.currentOrder.companyId
      this.shippingLabelUrlForm.fbmOrderId = this.currentOrder.id

      const validationResult = formRef.validate()
      if (validationResult.isValid) {
        await fbmService.saveCustomerShippingLabel(this.shippingLabelUrlForm)
        await this.getShippingLabels(this.currentOrder.id)
        this.$emit('emit-close')
      }
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
